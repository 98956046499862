import classNames from 'classnames';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DataLoading } from '@bottomless/common/components';
import { useDataEffect, useConditionalDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { EmailEditor } from '../../../components/EmailEditor/EmailEditor';

import {
  acknowledgeOrderAction,
  fetchOrderTrackingAction,
  replaceOrderAction,
  syncOrderTrackingAction,
  updateOrderAction,
  replaceOrderWithCoffeeCreditAction,
  getOrderNotesAction,
  getUnscannedOrdersAction,
  getProductsAction,
  getVendorAction,
  getVendorsAction,
  sendVendorNotificationAction,
} from '../../../store';
import { Batch } from './components/Batch';
import { Filters } from './components/Filters';
import { Heading } from './components/Heading';

import './OrderTracking.scss';

const Schema = Yup.object().shape({
  type: Yup.string()
    .oneOf(['unprocessedOrders'])
    .required('This field is required'),
});

const MODAL_FETCH_EASYPOST = 'EASYPOST';
const MODAL_VENDOR_EMAIL = 'VENDOR_EMAIL';
const MODAL_EMAIL = 'modal_email';

const OrderTrackingPageComponent = ({
  batches,
  getOrders,
  vendor,
  getVendor,
  getVendors,
  getProducts,
  replaceOrder,
  vendors,
  addToast,
  sendNotification,
  isLoading,
  acknowledgeOrder,
  updateOrder,
  syncOrderTracking,
  fetchOrderTracking,
  location,
  match: {
    params: { id },
  },
  replaceOrderWithCoffeeCredit,
  getOrderNotes,
}) => {
  const [selected, setSelected] = useState([]);
  const [orderLength, setOrderLength] = useState(0);
  const [products, setProducts] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);
  const [tracker, setTracker] = useState(null);
  const [status, setStatus] = useState(null);
  const [orderNotes, setOrderNotes] = useState();
  const [orderIds, setOrderIds] = useState();

  const query = useMemo(() => qs.parse(location.search), [location.search]);

  useEffect(() => {
    getVendors();
  }, []);

  useEffect(() => {
    getVendor();
    setSelected([]);
  }, [id]);

  useEffect(() => {
    getOrders();
  }, [query, id]);

  useEffect(() => {
    if (batches?.length) {
      const orderIds = [];
      batches?.length &&
        batches.forEach(batch => batch.orders && batch.orders.forEach(order => orderIds.push(order._id)));
      setOrderIds(orderIds);
    }
  }, [batches]);

  useConditionalDataEffect(orderIds, getOrderNotes, setOrderNotes, orderIds);

  useEffect(() => {
    setStatus(
      batches.reduce(
        (all, batch) => ({
          ...all,
          [batch._id]: { orders: batch.orders.length, ack: 0 },
        }),
        {}
      )
    );
  }, [batches]);

  useDataEffect(
    getProducts,
    response => {
      setProducts(get(response, 'docs', []).filter(p => !p.rotating));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  const onSelect = order => {
    if (selected.find(s => s._id === order._id)) {
      setSelected(selected.filter(s => s._id !== order._id));
    } else {
      setSelected([...selected, order]);
    }
  };

  const onBatchSelect = orders => {
    for (const order of orders) {
      if (selected.find(s => s._id === order._id)) {
        setSelected(selected => selected.filter(s => s._id !== order._id));
      } else {
        setSelected(selected => [...selected, order]);
      }
    }
  };

  const onAck = async order => {
    await acknowledgeOrder(order._id);

    const batch = order.batch_id || null;

    setStatus({
      ...status,
      [batch]: {
        ...status[batch],
        ack: status[batch].ack + 1,
      },
    });
    setOrderLength(orderLength - 1);
    addToast(`Order ${order._id} acknowledged`, 'success');
  };

  const batchAck = async () => {
    await Promise.all(selected.map(order => acknowledgeOrder(order._id)));
    setSelected([]);
    getOrders();
    addToast('Orders acknowledged', 'success');
  };

  const onSync = async id => {
    try {
      await syncOrderTracking(id);
      addToast('Order shipping status synced', 'success');
    } catch (e) {
      addToast(`Order sync failed ${e.message}`, 'error');
    }
  };

  const onFetchTracking = async order => {
    const { payload } = await fetchOrderTracking(order._id);
    setTracker(payload);
    toggle(MODAL_FETCH_EASYPOST);
  };

  const onReplace = async (id, data) => {
    try {
      const { isCoffeeCredit, source, discount_type, discount_comment } = data;
      if (isCoffeeCredit) {
        await replaceOrderWithCoffeeCredit(id, { source, discount_type, discount_comment });
        addToast('Order replaced with coffee credit', 'success');
      } else {
        await replaceOrder(id, data);
        addToast('Order replaced', 'success');
      }
      getOrders();
    } catch (e) {
      addToast(`Order replacement failed ${e.message}`, 'error');
    }
  };

  const toggle = type => {
    setType(type);
    setOpen(!isOpen);
  };

  const close = () => {
    setType(null);
    setOpen(false);
  };

  const orders = useMemo(() => {
    const res = batches.map(batch => batch.orders).flat();
    setOrderLength(res.length);
    return res;
  }, [batches]);

  const isVisible = batch => status[batch._id] && status[batch._id].ack < status[batch._id].orders;

  return (
    <PanelPage className="page-order-tracking" heading={<Heading vendors={vendors} vendor={vendor} />}>
      <div className="mb-4">
        <Filters isLoading={isLoading} hasData={batches && batches.length > 0} />
      </div>

      <DataLoading count={1} isLoading={isLoading} />

      {batches && batches.length > 0 && status && (
        <>
          <Row className="align-items-center">
            <Col className="text-right">
              <Button size="sm" color="success" disabled={selected.length === 0} onClick={batchAck} className="mr-2">
                Acknowledge ({selected.length})
              </Button>

              <Button
                size="sm"
                disabled={selected.length === 0}
                onClick={() => toggle(MODAL_VENDOR_EMAIL)}
                className="mr-2"
              >
                Send email ({selected.length})
              </Button>

              <Button size="sm" disabled={selected.length === 0} onClick={() => toggle(MODAL_EMAIL)} className="mr-2">
                Bulk Notification ({selected.length})
              </Button>

              <Button onClick={() => setSelected(orders)} color="transparent">
                Select all ({orderLength})
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              {batches.map(batch => (
                <div
                  key={batch._id}
                  className={classNames('mb-2', {
                    'd-none': !isVisible(batch),
                  })}
                >
                  <Batch
                    batch={batch}
                    onSelect={onSelect}
                    selected={selected}
                    onAck={onAck}
                    onSyncTracking={onSync}
                    onFetchTracking={onFetchTracking}
                    onUpdate={updateOrder}
                    products={products}
                    onReplace={onReplace}
                    addToast={addToast}
                    onBatchSelect={onBatchSelect}
                    orderNotes={orderNotes}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}

      <Modal isOpen={isOpen} toggle={close}>
        {modalType === MODAL_FETCH_EASYPOST && (
          <>
            <ModalHeader toggle={close}>Tracking</ModalHeader>
            <ModalBody>
              <pre>{JSON.stringify(tracker, null, 2)}</pre>
            </ModalBody>
          </>
        )}

        {modalType === MODAL_VENDOR_EMAIL && (
          <>
            <ModalHeader toggle={close}>Send notification</ModalHeader>
            <ModalBody>
              <Form
                initialValues={{ type: 'unprocessedOrders' }}
                validationSchema={Schema}
                onSubmit={data => sendNotification({ ...data, selected: selected.map(order => order._id) })}
                onSuccess={async () => {
                  await getOrders();
                  setOpen(false);
                }}
              >
                {({ isSubmitting }) => (
                  <>
                    <Field
                      label="Message"
                      name="type"
                      type="select"
                      options={{
                        unprocessedOrders: 'Unprocessed orders',
                      }}
                    />
                    <Field name="content" type="textarea" label="Additional content" />

                    <SubmitButton color="success" isSubmitting={isSubmitting} loadingText="Sending" block>
                      Send
                    </SubmitButton>
                  </>
                )}
              </Form>
            </ModalBody>
          </>
        )}

        {modalType === MODAL_EMAIL && (
          <>
            <ModalHeader toggle={toggle}>Send Bulk notification</ModalHeader>
            <ModalBody>
              <EmailEditor
                isNotification={true}
                isBulk={true}
                context={{
                  orderIds: selected.map(order => order._id),
                  userEmails: selected.map(order => order.user_id.local.email),
                }}
                onSuccess={() => {
                  batchAck();
                  toggle();
                }}
                source="order_tracking"
              />
            </ModalBody>
          </>
        )}
      </Modal>
    </PanelPage>
  );
};

OrderTrackingPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  getVendor: PropTypes.func.isRequired,
  getVendors: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  replaceOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  vendors: PropTypes.array,
  vendor: PropTypes.object,
  batches: PropTypes.array,
  addToast: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  acknowledgeOrder: PropTypes.func.isRequired,
  syncOrderTracking: PropTypes.func.isRequired,
  fetchOrderTracking: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  replaceOrderWithCoffeeCredit: PropTypes.func.isRequired,
  getOrderNotes: PropTypes.func.isRequired,
};

export const OrderTrackingPage = connect(
  ({ adminVendor }, { match }) => ({
    batches: adminVendor.unscannedOrders,
    isLoading: adminVendor.isLoading,
    vendor: adminVendor.vendor && match.params.id === adminVendor.vendor._id ? adminVendor.vendor : null,
    vendors: adminVendor.vendors,
  }),
  (
    dispatch,
    {
      location: { search },
      match: {
        params: { id },
      },
    }
  ) => ({
    getOrders: params => dispatch(getUnscannedOrdersAction(id, { ...params, ...qs.parse(search) })),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    getVendor: () => dispatch(getVendorAction(id)),
    getVendors: () => dispatch(getVendorsAction()),
    updateOrder: (id, data) => dispatch(updateOrderAction(id, data)),
    sendNotification: data => dispatch(sendVendorNotificationAction(id, data)),
    acknowledgeOrder: id => dispatch(acknowledgeOrderAction(id)),
    syncOrderTracking: id => dispatch(syncOrderTrackingAction(id)),
    fetchOrderTracking: id => dispatch(fetchOrderTrackingAction(id)),
    getProducts: data => dispatch(getProductsAction(data)),
    replaceOrder: (id, data) => dispatch(replaceOrderAction(id, data)),
    replaceOrderWithCoffeeCredit: (id, data) => dispatch(replaceOrderWithCoffeeCreditAction(id, data)),
    getOrderNotes: id => dispatch(getOrderNotesAction(id)),
  })
)(OrderTrackingPageComponent);
