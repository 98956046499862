import classNames from 'classnames';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import React, { useEffect, useMemo, useState, Fragment, useCallback } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton, DataLoading } from '@bottomless/common/components';
import { useUnmount, useDataEffect, useConditionalDataEffect } from '@bottomless/common/hooks';
import { connectWithAbort, addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import { EmailEditor } from '../../../components/EmailEditor/EmailEditor';
import { tabs, superTabs } from './components/Tabs';
import {
  acknowledgeOrderAction,
  replaceOrderAction,
  syncOrderTrackingAction,
  updateOrderAction,
  replaceOrderWithCoffeeCreditAction,
  syncOrderBottomlessTrackingAction,
  getOrderNotesAction,
} from '../../../store';
import { getProductsAction } from '../../../store/admin/product';
import { getAllUnscannedOrdersAction, sendVendorNotificationAction } from '../../../store/admin/vendor';
import { Batch } from './components/Batch';
import { Filters } from './components/Filters';
import { Heading } from './components/Heading';

import './OrderTracking.scss';

const Schema = Yup.object().shape({
  type: Yup.string()
    .oneOf(['unprocessedOrders'])
    .required('This field is required'),
});

const MODAL_VENDOR_EMAIL = 'VENDOR_EMAIL';
const MODAL_EMAIL = 'modal_email';

const OrderTrackingAllPageComponent = ({
  allBatches,
  getOrders,
  getProducts,
  replaceOrder,
  addToast,
  sendNotification,
  isLoading,
  acknowledgeOrder,
  updateOrder,
  syncOrderTracking,
  location,
  match: {
    params: { status },
  },
  abortRequests,
  replaceOrderWithCoffeeCredit,
  syncOrderBottomlessTracking,
  getOrderNotes,
}) => {
  const [selected, setSelected] = useState([]);
  const [orderLength, setOrderLength] = useState(0);
  const [products, setProducts] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [modalType, setType] = useState(null);
  const [orderNotes, setOrderNotes] = useState();
  const [orderIds, setOrderIds] = useState();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  useUnmount(abortRequests);

  useEffect(() => {
    getOrders(status);
    setSelected([]);
  }, []);

  useEffect(() => {
    abortRequests();
    getOrders(status);
    setSelected([]);
  }, [status]);

  useEffect(() => {
    abortRequests();
    getOrders(status);
  }, [query]);

  useEffect(() => {
    if (allBatches?.length) {
      const orderIds = [];
      allBatches?.length &&
        allBatches.forEach(vendor =>
          vendor.batches.forEach(batch => batch.orders.forEach(order => orderIds.push(order._id)))
        );
      setOrderIds(orderIds);
    }
  }, [allBatches]);

  useConditionalDataEffect(orderIds, getOrderNotes, setOrderNotes, orderIds);

  const updateNotes = useCallback(async () => {
    const { payload, error } = await getOrderNotes(orderIds);
    if (!error) {
      setOrderNotes(payload);
    }
  }, [getOrderNotes, orderIds]);

  useDataEffect(
    getProducts,
    response => {
      setProducts(get(response, 'docs', []).filter(p => !p.rotating));
    },
    { status: 'active', sort: 'name', dir: 1 }
  );

  const onSelect = order => {
    if (selected.find(s => s._id === order._id)) {
      setSelected(selected.filter(s => s._id !== order._id));
    } else {
      setSelected([...selected, order]);
    }
  };

  const onBatchSelect = orders => {
    for (const order of orders) {
      if (selected.find(s => s._id === order._id)) {
        setSelected(selected => selected.filter(s => s._id !== order._id));
      } else {
        setSelected(selected => [...selected, order]);
      }
    }
  };

  const onAck = async order => {
    acknowledgeOrder(order._id);
    setOrderLength(orderLength - 1);
  };

  const batchAck = async () => {
    await Promise.all(selected.map(order => acknowledgeOrder(order._id)));
    setSelected([]);
    getOrders(status);
    addToast('Orders acknowledged', 'success');
  };

  const onSync = async id => {
    try {
      await syncOrderTracking(id);
      addToast('Order shipping status synced', 'success');
    } catch (e) {
      addToast(`Order sync failed ${e.message}`, 'error');
    }
  };

  const onBottomlessTracking = async id => {
    try {
      await syncOrderBottomlessTracking(id);
      addToast('Order Bottomless shipping synced', 'success');
    } catch (e) {
      addToast(`Order Bottomless shipping sync failed ${e.message}`, 'error');
    }
  };

  const onReplace = async (id, data) => {
    try {
      const { isCoffeeCredit, source, discount_type, discount_comment } = data;
      if (isCoffeeCredit) {
        await replaceOrderWithCoffeeCredit(id, { source, discount_type, discount_comment });
        addToast('Order replaced with coffee credit', 'success');
      } else {
        await replaceOrder(id, data);
        addToast('Order replaced', 'success');
      }
      getOrders(status);
    } catch (e) {
      addToast(`Order replacement failed ${e.message}`, 'error');
    }
  };

  const toggle = type => {
    setType(type);
    setOpen(!isOpen);
  };

  const close = () => {
    setType(null);
    setOpen(false);
  };

  const sendBulkEmail = async data => {
    const vendorIds = [...new Set(selected.map(order => order.subvendor_id._id))];
    return await Promise.all(
      vendorIds.map(vendor =>
        sendNotification(vendor, {
          ...data,
          selected: selected.filter(order => order.subvendor_id._id === vendor).map(order => order._id),
        })
      )
    );
  };

  const orders = useMemo(() => {
    let res = [];
    if (allBatches && allBatches.length > 0) {
      res = allBatches.map(vendors => vendors.batches.map(batch => batch.orders).flat()).flat();
    }
    setOrderLength(res.length);
    return res;
  }, [allBatches]);

  const vendorCheck = vendor => {
    return vendor.batches && vendor.batches.map(e => e.orders).flat().length > 0;
  };

  return (
    <PanelPage
      className="page-order-tracking"
      title="Order Tracking"
      tabs={status.includes('tracking') ? superTabs : tabs}
    >
      <div className="mb-4">
        <Filters isLoading={isLoading} hasData={allBatches && allBatches.length > 0} isAll={true} />
      </div>

      <DataLoading count={1} isLoading={isLoading} />

      {allBatches && allBatches.length > 0 && (
        <>
          <Row className="align-items-center">
            <Col className="text-right">
              <Button size="sm" color="success" disabled={selected.length === 0} onClick={batchAck} className="mr-2">
                Acknowledge ({selected.length})
              </Button>

              <Button
                size="sm"
                disabled={selected.length === 0}
                onClick={() => toggle(MODAL_VENDOR_EMAIL)}
                className="mr-2"
              >
                Send email ({selected.length})
              </Button>

              <Button size="sm" disabled={selected.length === 0} onClick={() => toggle(MODAL_EMAIL)} className="mr-2">
                Bulk Notification ({selected.length})
              </Button>

              <Button onClick={() => setSelected(orders)} color="transparent">
                Select all ({orderLength})
              </Button>
            </Col>
          </Row>
          {allBatches.map(vendor => {
            if (vendorCheck(vendor)) {
              return (
                <Fragment key={vendor._id._id}>
                  <Heading vendor={vendor._id} isAll={true} />
                  <Row>
                    <Col xs="12">
                      {vendor.batches.map(
                        (batch, i) =>
                          batch.orders &&
                          batch.orders.length > 0 && (
                            <div
                              key={i}
                              className={classNames('mb-2', {
                                'd-none': false,
                              })}
                            >
                              <Batch
                                batch={batch}
                                onSelect={onSelect}
                                selected={selected}
                                onAck={onAck}
                                onSyncTracking={onSync}
                                onSyncBottomlessTracking={onBottomlessTracking}
                                onUpdate={updateOrder}
                                products={products}
                                onReplace={onReplace}
                                showFulfillmentTime={status === 'pre_transit' ? true : false}
                                addToast={addToast}
                                onBatchSelect={onBatchSelect}
                                orderNotes={orderNotes}
                                updateNotes={updateNotes}
                              />
                            </div>
                          )
                      )}
                    </Col>
                  </Row>
                </Fragment>
              );
            }
          })}
        </>
      )}

      <Modal isOpen={isOpen} toggle={close}>
        {modalType === MODAL_VENDOR_EMAIL && (
          <>
            <ModalHeader toggle={close}>Send notification</ModalHeader>
            <ModalBody>
              <Form
                initialValues={{ type: 'unprocessedOrders' }}
                validationSchema={Schema}
                onSubmit={data => sendBulkEmail(data)}
                onSuccess={async () => {
                  setOpen(false);
                  addToast('Emails sent', 'success');
                }}
              >
                {({ isSubmitting }) => (
                  <>
                    <Field
                      label="Message"
                      name="type"
                      type="select"
                      options={{
                        unprocessedOrders: 'Unprocessed orders',
                      }}
                    />
                    <Field name="content" type="textarea" label="Additional content" />

                    <SubmitButton color="success" isSubmitting={isSubmitting} loadingText="Sending" block>
                      Send
                    </SubmitButton>
                  </>
                )}
              </Form>
            </ModalBody>
          </>
        )}

        {modalType === MODAL_EMAIL && (
          <>
            <ModalHeader toggle={toggle}>Send Bulk notification</ModalHeader>
            <ModalBody>
              <EmailEditor
                isNotification={true}
                isBulk={true}
                context={{
                  orderIds: selected.map(order => order._id),
                  userEmails: selected.map(order => order.user_id.local.email),
                }}
                onSuccess={() => {
                  batchAck();
                  toggle();
                  updateNotes();
                }}
                source="order_tracking"
              />
            </ModalBody>
          </>
        )}
      </Modal>
    </PanelPage>
  );
};

OrderTrackingAllPageComponent.propTypes = {
  getOrders: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  replaceOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  allBatches: PropTypes.array,
  addToast: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  acknowledgeOrder: PropTypes.func.isRequired,
  syncOrderTracking: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }).isRequired,
  abortRequests: PropTypes.func.isRequired,
  replaceOrderWithCoffeeCredit: PropTypes.func.isRequired,
  syncOrderBottomlessTracking: PropTypes.func.isRequired,
  getOrderNotes: PropTypes.func.isRequired,
};

export const OrderTrackingAllPage = connectWithAbort(
  ({ adminVendor }) => ({
    allBatches: adminVendor.allUnscannedOrders,
    isLoading: adminVendor.isLoading,
    vendors: adminVendor.vendors,
  }),
  (dispatch, { location: { search } }) => ({
    getOrders: (status, params) => dispatch(getAllUnscannedOrdersAction(status, { ...params, ...qs.parse(search) })),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    updateOrder: (id, data) => dispatch(updateOrderAction(id, data)),
    sendNotification: (id, data) => dispatch(sendVendorNotificationAction(id, data)),
    acknowledgeOrder: id => dispatch(acknowledgeOrderAction(id)),
    syncOrderTracking: id => dispatch(syncOrderTrackingAction(id)),
    syncOrderBottomlessTracking: id => dispatch(syncOrderBottomlessTrackingAction(id)),
    getProducts: data => dispatch(getProductsAction(data)),
    replaceOrder: (id, data) => dispatch(replaceOrderAction(id, data)),
    replaceOrderWithCoffeeCredit: (id, data) => dispatch(replaceOrderWithCoffeeCreditAction(id, data)),
    getOrderNotes: id => dispatch(getOrderNotesAction(id)),
  })
)(OrderTrackingAllPageComponent);
