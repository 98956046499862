import React, { useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { CheckSquare, Square } from 'react-feather';
import { Button, Card, CardTitle } from 'reactstrap';
import { Order } from './Order';

const sortOrder = {
  delivered: 1,
  out_for_delivery: 2,
  in_transit: 3,
  accepted: 4,
  pre_transit: 5,
  unknown: 6,
};

const BatchComponent = ({
  batch,
  products,
  onSelect,
  selected,
  onUpdate,
  onAck,
  onSyncTracking,
  onReplace,
  showFulfillmentTime,
  addToast,
  onBatchSelect,
  onSyncBottomlessTracking,
  orderNotes,
  updateNotes,
}) => {
  const orders = useMemo(
    () =>
      batch.orders.sort((a, b) => (sortOrder[get(a, 'usps.status', 5)] > sortOrder[get(b, 'usps.status', 5)] ? 1 : -1)),
    [batch]
  );

  const onBatchClick = () => {
    onBatchSelect(orders);
    setBatchChecked(!batchChecked);
  };
  const [batchChecked, setBatchChecked] = useState(false);

  return (
    <>
      <Card body className={`${batch._id} p-0`}>
        <CardTitle className="d-flex justify-content-between border-bottom mb-1 p-2 font-weight-bold">
          <span>{batch._id}</span>

          <span className="ml-auto">
            <Button onClick={onBatchClick} color="transparent" className="btn-sm">
              {batchChecked ? <CheckSquare size="15" /> : <Square size="15" />}
            </Button>
            {batch.orders.length}/{batch.total}
          </span>
        </CardTitle>

        {orders.map(order => (
          <div key={order._id}>
            <Order
              order={{ ...order, notes: orderNotes ? orderNotes[order._id] : undefined }}
              onSelect={onSelect}
              onAck={onAck}
              onUpdate={onUpdate}
              selected={Boolean(selected.find(o => o._id === order._id))}
              onSyncTracking={onSyncTracking}
              products={products}
              onReplace={onReplace}
              showFulfillmentTime={showFulfillmentTime}
              addToast={addToast}
              onSyncBottomlessTracking={onSyncBottomlessTracking}
              updateNotes={updateNotes}
            />
          </div>
        ))}
      </Card>
    </>
  );
};

BatchComponent.propTypes = {
  selected: PropTypes.array,
  products: PropTypes.array,
  onSelect: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onAck: PropTypes.func.isRequired,
  onSyncTracking: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  batch: PropTypes.shape({
    _id: PropTypes.string,
    total: PropTypes.number.isRequired,
    orders: PropTypes.array.isRequired,
  }).isRequired,
  showFulfillmentTime: PropTypes.bool,
  onSyncBottomlessTracking: PropTypes.func.isRequired,
  onBatchSelect: PropTypes.func,
  orderNotes: PropTypes.object,
  updateNotes: PropTypes.func,
};

export const Batch = memo(BatchComponent);
