import { debounce, get, pickBy, has } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Printer } from 'react-feather';
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, DataLoading, Pagination } from '@bottomless/common/components';
import { useDataEffect, useToggle } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { PanelPage } from '../../../layouts/PanelPage/PanelPage';
import {
  changeShipmentStatusAction,
  createBatchAction,
  createScanFormAction,
  getBatchAction,
  getShipmentsAction,
  purchaseScaleShipmentAction,
  syncShipmentStatusAction,
  updateTrackingNumberAction,
  triggerScaleShipmentSmokeTestAction,
} from '../../../store/admin/scale-shipments';
import { BatchModal } from './components/BatchModal';
import { ShipmentComponent } from './components/Shipment';
import { UpdateTrackingModal } from './components/UpdateTrackingModal';
import { tabs } from './Tabs';
import { getScaleDeviceAction } from '../../../store';
import { ScaleDeviceSelectModal } from '../ScaleShipmentsv2/components/ScaleDeviceSelectModal';

const Schema = Yup.object().shape({
  status: Yup.string().nullable(true),
  search: Yup.string(),
});

const statuses = {
  null: 'All',
  requested: 'Requested',
  created: 'Created',
  shipped: 'Shipped',
  arrived: 'Arrived',
  failure: 'Failure',
  canceled: 'Canceled',
};

const ScaleShipmentsPageComponent = ({
  addToast,
  getShipments,
  syncStatus,
  changeStatus,
  purchaseScaleShipment,
  createBatch,
  createScanForm,
  getBatch,
  history,
  location,
  match: {
    params: { type },
  },
  shipments,
  updateTrackingNumber,
  getScaleDevice,
  triggerScaleShipmentSmokeTest,
}) => {
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  const formRef = useRef();

  const [selected, setSelected] = useState([]);
  const [printable, setPrintable] = useState([]);
  const [withoutLabel, setWithoutLabel] = useState([]);
  const [batch, setBatch] = useState([]);
  const [batchId, setBatchId] = useState(null);
  const [pendingBatch, setPendingBatch] = useState(false);
  const [isOpen, toggle] = useToggle();
  const [isUpdateTrackingModalOpen, toggleUppdateTrackingModal] = useToggle();
  const [isBatchDetailsOpened, toggleBatchDetails] = useToggle();
  const [shipmentToUpdate, setShipmentToUpdate] = useState();
  const [scaleDevices, setScaleDevices] = useState([]);
  const [isScaleDeviceSelectModalOpen, toggleScaleDeviceSelectModal] = useToggle();

  const [filters, setFilters] = useState(query);

  useDataEffect(getScaleDevice, setScaleDevices);

  useDataEffect(getShipments, () => {}, { type, ...query }, [], [type, query]);

  const onEdit = async shipment => {
    await changeStatus(shipment);
    addToast(`Shipment status changed to ${shipment.status}`);
  };

  const syncStatusHandler = async id => {
    try {
      await syncStatus(id);
      addToast('Status fetched from EasyPost');
    } catch (e) {
      addToast('Failed to sync status');
    }
  };

  const purchaseShipmentHandler = async (id, data) => {
    try {
      await purchaseScaleShipment(id, data);
      addToast('Label purchased');
    } catch (e) {
      addToast('Failed to purchase label');
    }
  };

  const updateTracking = async data => {
    try {
      await updateTrackingNumber(shipmentToUpdate?._id, data);
      addToast('Traking number updated');
      setShipmentToUpdate();
      toggleUppdateTrackingModal();
    } catch (e) {
      addToast('Failed to update tracking number');
    }
  };

  const onSelect = shipment => {
    if (selected.find(s => s._id === shipment._id)) {
      setSelected(selected.filter(s => s._id !== shipment._id));
    } else {
      setSelected([...selected, shipment]);
    }
  };

  const purchaseBatch = async data => {
    setPendingBatch(true);
    await Promise.all(withoutLabel.map(({ _id }) => purchaseScaleShipment(_id, data).catch(e => addToast(e.message))));
    setBatch(batch.map(s => shipments.docs.find(c => c._id === s._id)));
    toggleBatchDetails();
    toggleScaleDeviceSelectModal();
    setPendingBatch(false);
  };

  const onFilterChange = (filter, value) => setFilters({ ...filters, [filter]: value });

  const onClearClick = () => {
    setFilters({});
    formRef.current.resetForm({ status: '', search: '' });
  };

  const onSubmit = data => {
    const filename = `${moment().format('m-d')}-labels-${data.layout}`;

    const labels = printable.length > 0 ? `${printable.map(({ _id }) => `shipments=${_id}`).join('&')}` : '';

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/scale-shipments/labels/${filename}?${labels}&layout=${data.layout}`
    );

    setSelected([]);
    toggle();
  };

  const selectToday = () => {
    const tomorrow = moment
      .utc()
      .add(1, 'days')
      .startOf('day');

    const todayShipment = shipments.docs.filter(s => {
      if (!['requested', 'created'].includes(s.status)) {
        return false;
      }

      const firstOrderDate = has(s.firstOrder, 'override_fulfillment_date')
        ? get(s.firstOrder, 'override_fulfillment_date')
        : get(s.firstOrder, 'date_fulfilled');

      return !firstOrderDate || moment.utc(firstOrderDate).isBefore(tomorrow);
    });

    setSelected(todayShipment);
  };

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(pickBy(filters, v => v && v !== 'null')),
    });
  }, [filters, history, location.pathname]);

  useEffect(() => {
    onClearClick();
  }, [type]);

  useEffect(() => {
    setPrintable(selected.filter(shipment => shipment.label_url));
    setWithoutLabel(selected.filter(shipment => !shipment.tracking_number));
    setBatch(selected.filter(shipment => !shipment.batch_id));
  }, [selected]);

  const statusFilters = useMemo(
    () => (type === 'today' ? { null: 'All', requested: 'Requested', created: 'Created' } : statuses),
    [type]
  );

  const Filters = (
    <Form
      inline
      innerRef={formRef}
      initialValues={{ status: '', ...filters }}
      validationSchema={Schema}
      onSubmit={() => {}}
      onSuccess={() => {}}
    >
      {() => (
        <>
          <Row className="w-100 align-items-center">
            <Col xs="2">
              <Field
                name="status"
                label="status"
                type="select"
                options={statusFilters}
                onChange={e => onFilterChange('status', e.target.value)}
              />
            </Col>
            <Col className="pr-0">
              <Field
                className="w-100 up-label"
                name="search"
                label="Search"
                placeholder={`Search by ${['_id', 'user_id', 'tracking_number', 'shipping_status'].join(', ')}`}
                onChange={debounce(event => {
                  onFilterChange('search', event.target.value);
                }, 200)}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );

  const onSmokeTest = useCallback(() => {
    triggerScaleShipmentSmokeTest();
    addToast('Scale shipment smoke test triggered!', 'success');
  }, [triggerScaleShipmentSmokeTest, addToast]);

  return (
    <>
      <PanelPage
        title="Scale shipments"
        withUpNext={false}
        className="page-scale-shipments"
        tabs={tabs}
        heading={
          type === 'onboarding' && (
            <Button onClick={onSmokeTest} color="success">
              Scale Shipment Smoke test
            </Button>
          )
        }
      >
        <Row className="mb-4">
          <Col xs="12">{Filters}</Col>
        </Row>
        <DataLoading count={(get(shipments, 'docs') || { length: 0 }).length} isLoading={shipments === null}>
          <div className="text-center">
            <span className="d-block mb-3">No data matching selected filters.</span>
            <Button size="sm" onClick={onClearClick}>
              Clear filters
            </Button>
          </div>
        </DataLoading>
        {shipments && shipments.docs.length > 0 && (
          <>
            <Row className="mb-2 justify-content-end align-items-center">
              <Col xs="12" className="text-right">
                {printable.length > 0 && (
                  <Button onClick={() => toggle()} color="info">
                    <span className="mr-2">Print labels ({printable.length})</span>
                    <Printer size="14" />
                  </Button>
                )}
                {withoutLabel.length > 0 && (
                  <Button
                    onClick={toggleScaleDeviceSelectModal}
                    disabled={pendingBatch}
                    color="success"
                    className="btn btn-outline"
                  >
                    Buy shipment ({withoutLabel.length})
                  </Button>
                )}
                <Button
                  onClick={() => setSelected(shipments.docs.filter(s => ['requested', 'created'].includes(s.status)))}
                  color="transparent"
                  className="ml-2"
                >
                  Select all ({shipments.docs.filter(s => ['requested', 'created'].includes(s.status)).length})
                </Button>
                <Button onClick={selectToday} color="transparent" className="ml-2">
                  Select today
                </Button>
              </Col>
            </Row>
            {shipments.docs.map(shipment => (
              <ShipmentComponent
                key={shipment._id}
                shipment={shipment}
                onEdit={onEdit}
                syncStatus={syncStatusHandler}
                purchaseScaleShipment={purchaseShipmentHandler}
                onSelect={onSelect}
                onBatchClick={batchId => {
                  setBatchId(batchId);
                  toggleBatchDetails();
                }}
                selected={selected.some(s => s._id === shipment._id)}
                setShipmentToUpdate={setShipmentToUpdate}
                toggleUppdateTrackingModal={toggleUppdateTrackingModal}
                scaleDevices={scaleDevices}
              />
            ))}
            <div className="mt-2">
              <Pagination collection={shipments} onPageChange={getShipments} />
            </div>
            <Modal isOpen={isOpen} toggle={toggle} size="sm">
              <ModalHeader toggle={toggle}>Select format</ModalHeader>
              <ModalBody>
                <Form
                  initialValues={{ layout: 'labelPrinter' }}
                  validationSchema={Schema}
                  onSubmit={onSubmit}
                  onSuccess={() => {}}
                >
                  {() => (
                    <>
                      <Field
                        type="select"
                        name="layout"
                        label="Format"
                        options={{ labelPrinter: 'Label Printer (4x6)', fullPage: 'Full page sheet' }}
                      />
                      <Button color="success">
                        <span className="mr-2">Print labels</span>
                        <Printer size="14" />
                      </Button>
                    </>
                  )}
                </Form>
              </ModalBody>
            </Modal>

            <BatchModal
              opened={isBatchDetailsOpened}
              createBatch={createBatch}
              getBatch={getBatch}
              createScanForm={createScanForm}
              batchId={batchId}
              shipments={batch}
            />

            <UpdateTrackingModal
              isOpen={isUpdateTrackingModalOpen}
              toggle={toggleUppdateTrackingModal}
              onSubmit={updateTracking}
              shipmentToUpdate={shipmentToUpdate}
            />

            <ScaleDeviceSelectModal
              isOpen={isScaleDeviceSelectModalOpen}
              toggle={toggleScaleDeviceSelectModal}
              scaleDevices={scaleDevices}
              onSubmit={purchaseBatch}
            />
          </>
        )}
      </PanelPage>
    </>
  );
};

ScaleShipmentsPageComponent.propTypes = {
  addToast: PropTypes.func.isRequired,
  getShipments: PropTypes.func.isRequired,
  syncStatus: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  purchaseScaleShipment: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired,
  createScanForm: PropTypes.func.isRequired,
  getBatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  shipments: PropTypes.object,
  updateTrackingNumber: PropTypes.func.isRequired,
  getScaleDevice: PropTypes.func.isRequired,
  triggerScaleShipmentSmokeTest: PropTypes.func.isRequired,
};

export const ScaleShipmentsPage = connect(
  ({ adminScaleShipments: { data: shipments } }) => ({ shipments }),
  dispatch => ({
    addToast: message => dispatch(addToastAction(message)),
    getShipments: data => dispatch(getShipmentsAction(data)),
    syncStatus: trackingNumber => dispatch(syncShipmentStatusAction(trackingNumber)),
    changeStatus: data => dispatch(changeShipmentStatusAction(data)),
    createBatch: data => dispatch(createBatchAction(data)),
    createScanForm: id => dispatch(createScanFormAction(id)),
    getBatch: id => dispatch(getBatchAction(id)),
    purchaseScaleShipment: (id, data) => dispatch(purchaseScaleShipmentAction(id, data)),
    updateTrackingNumber: (id, data) => dispatch(updateTrackingNumberAction(id, data)),
    getScaleDevice: () => dispatch(getScaleDeviceAction()),
    triggerScaleShipmentSmokeTest: () => dispatch(triggerScaleShipmentSmokeTestAction()),
  })
)(ScaleShipmentsPageComponent);
