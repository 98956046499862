import { SyncOrderTrackingTypes } from '../order';
import {
  GetUnscannedOrdersTypes,
  GetAllUnscannedOrdersTypes,
  GetVendorTypes,
  GetVendorsTypes,
  GetPackagingSummaryTypes,
} from './vendor.actions';

const initialState = {
  vendors: [],
  unscannedOrders: [],
  vendor: null,
  isLoading: false,
  packagingSummary: {
    data: [],
    isLoading: false,
  },
  hasOrderNotes: false,
};

export const adminVendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetUnscannedOrdersTypes.REQUEST:
      return { ...state, unscannedOrders: [], isLoading: true };

    case GetUnscannedOrdersTypes.SUCCESS:
      return {
        ...state,
        unscannedOrders: action.payload,
        isLoading: false,
      };

    case GetAllUnscannedOrdersTypes.REQUEST:
      return { ...state, allUnscannedOrders: [], isLoading: true, hasOrderNotes: false };

    case GetAllUnscannedOrdersTypes.SUCCESS:
      return {
        ...state,
        allUnscannedOrders: action.payload,
        isLoading: false,
      };
    case SyncOrderTrackingTypes.SUCCESS:
      return {
        ...state,
        allUnscannedOrders: (state?.allUnscannedOrders || []).map(vendor => ({
          ...vendor,
          batches: (vendor.batches || []).map(batch => ({
            ...batch,
            orders: (batch.orders || []).map(order => {
              if (order._id === action.payload._id) {
                return {
                  ...order,
                  tracking_updates: action.payload.tracking_updates,
                  tracking_status: action.payload.tracking_status,
                  usps_tracking: action.payload.usps_tracking,
                };
              }
              return order;
            }),
          })),
        })),
        unscannedOrders: (state?.unscannedOrders || []).map(batch => ({
          ...batch,
          orders: (batch.orders || []).map(order => {
            if (order._id === action.payload._id) {
              return {
                ...order,
                tracking_updates: action.payload.tracking_updates,
                tracking_status: action.payload.tracking_status,
                usps_tracking: action.payload.usps_tracking,
              };
            }
            return order;
          }),
        })),
        isLoading: false,
      };

    case GetVendorTypes.REQUEST:
    case GetVendorTypes.FAILURE:
      return {
        ...state,
        vendor: null,
      };

    case GetVendorTypes.SUCCESS:
      return {
        ...state,
        vendor: action.payload,
      };

    case GetVendorsTypes.REQUEST:
    case GetVendorsTypes.FAILURE:
      return {
        ...state,
        vendors: [],
      };

    case GetVendorsTypes.SUCCESS:
      return {
        ...state,
        vendors: action.payload,
      };

    case GetPackagingSummaryTypes.REQUEST:
      return { ...state, data: [], isLoading: true };

    case GetPackagingSummaryTypes.FAILURE:
      return {
        ...state,
        packagingSummary: { isLoading: false, data: [] },
      };

    case GetPackagingSummaryTypes.SUCCESS:
      return {
        ...state,
        packagingSummary: { isLoading: false, data: action.payload },
      };

    default:
      return state;
  }
};
